import axios from 'axios';
import { getAuthToken } from '../core-utils/Helper/helper';
import AuthService from './auth-service/AuthService';

const axiosInstance = axios.create({});

const MAX_UNAUTHORIZED_ACCESS_RETRY_COUNT = 3;
let unauthorizedCount = 0;

axiosInstance.interceptors.request.use((config) => {
  config.headers = { ...config.headers, token: getAuthToken() };
  return config;
});

axiosInstance.interceptors.response.use((response) => {
  unauthorizedCount = 0;
  return response;
}, async (error) => {
  if (error?.response?.status === 403 && getAuthToken()) {
    unauthorizedCount++;
    if (unauthorizedCount >= MAX_UNAUTHORIZED_ACCESS_RETRY_COUNT) {
      console.log("%c There are consistent 403 responses from the server", 'color: red; font-weight: bold;');
      try {
        const token = getAuthToken();
        if (token) {
          await AuthService.refreshToken(token);
          console.log("%c Hearbeat is still goint thru. Ignoring temparary flurry for 403s", 'color: green; font-weight: bold;');
          unauthorizedCount = 0;
        }
      } catch (refreshError: any) {
        if (refreshError?.response?.status === 403) {
          console.log("%c Token may have been invalidated. Kicking the user out.", 'color: red; font-weight: bold;');
          window.location.href = '/logout';
        }
      }
    }
  } else {
    unauthorizedCount = 0;
  }
  return Promise.reject(error);
});

export default axiosInstance;

